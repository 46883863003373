<template>
  <b-row>
    <b-col sm="12">
      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col sm="7">
            <h4 id="traffic" class="card-title mb-0">Contenidos - Sliders</h4>
            <div class="small text-muted">Administrar sliders para mostrar sitio web</div>
          </b-col>

          <b-col sm="5">
            <b-button @click="add()" type="button" variant="primary" size="sm" class="btn-pill float-right ml-2" v-b-tooltip.hover title="Agregar Sliders">
              <i class="fa fa-plus"></i> Agregar
            </b-button>

            <b-col sm="12" class="d-none d-md-block" v-if="!table.mostrarFiltros">
              <b-button type="button" variant="outline-dark" size="sm" class="btn-pill float-right" @click="table.mostrarFiltros=true" v-b-tooltip.hover title="Mostrar filtros">
                <i class="fa fa-filter"></i> Mostrar Filtros
              </b-button>
            </b-col>
            <b-col sm="12" class="d-none d-md-block" v-else>
              <b-button type="button" variant="outline-dark" size="sm" class="btn-pill float-right" v-if="table.mostrarFiltros" @click="table.mostrarFiltros=false" v-b-tooltip.hover title="Ocultar filtros">
                <i class="fa fa-window-close"></i>
              </b-button>
            </b-col>
          </b-col>          
        </b-row>
      </b-card>

      <b-card header-tag="header" footer-tag="footer">
        <b-row v-if="table.mostrarFiltros">
          <b-col sm="4">
            <b-form-group>
              <b-input-group>
                <b-form-input type="text" size="sm" placeholder="Ingrese su busqueda" v-model="table.filter"></b-form-input>
                <b-input-group-prepend>
                  <b-button variant="dark" size="sm" :disabled="!table.filter" @click="table.filter = ''">
                    <i class="fa fa-filter"></i> Filtrar
                  </b-button>
                </b-input-group-prepend>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-table class="mb-0"
                    responsive="sm"
                    head-variant="dark"
                    :hover="true"
                    :small="true"
                    :fixed="false"
                    :items="table.items"
                    :fields="table.fields"
                    :filter="table.filter"
                    :current-page="table.currentPage"
                    :per-page="table.perPage"
                    v-if="table.items.length">

                <template v-slot:cell(id)="data">
                  <b>{{ data.item.id }}</b>
                </template>

                <template v-slot:cell(image)="data">                  
                  <b-img-lazy v-bind="imageProps" :src="data.item.image" :alt="data.item.name" v-if="data.item.image"></b-img-lazy>
                  <b-badge variant="dark" v-else>No disponible</b-badge>
                </template>         

                <template v-slot:cell(boton)="data">                      
                  <div v-if="data.item.text_button">
                    <div>{{data.item.text_button}}</div>
                    <b-link :href="data.item.link" v-if="data.item.link" target="_blank">Ver Link</b-link>                  
                    <b-badge variant="secondary" v-else>Link no disponible</b-badge>
                  </div>                                  
                </template>        


                <template v-slot:cell(titulo)="data">                      
                  <div v-if="data.item.title || data.item.subtitle">
                    <b>{{data.item.title}}</b><br>
                    <i>{{data.item.subtitle}}</i>
                  </div>                    
                  <b-badge variant="secondary" v-else>Títulos no disponible</b-badge>                
                </template>  

                <template v-slot:cell(status)="data">                      
                  <b-badge variant="success" v-if="data.item.status">Activo</b-badge>
                  <b-badge variant="danger" v-else>Inactivo</b-badge>
                </template>   

                <template v-slot:cell(order)="data">                      
                  <b-badge variant="dark" v-if="data.item.order">{{data.item.order}}</b-badge>
                  <b-badge variant="secondary" v-else>Sin Orden</b-badge>
                </template>   

                <template v-slot:cell(f_action)="data">
                  <b-dropdown right text="Acción" size="sm" variant="outline-dark" class="pull-right">
                    <b-dropdown-item @click="edit(data.item)">
                      <i class="fa fa-pencil ml-0 mr-0" style="color:blue"></i> Editar
                    </b-dropdown-item>
                    <b-dropdown-item @click="remove(data.item)">
                      <i class="fa fa-trash mr-0 ml-0" style="color:red"></i> Eliminar
                    </b-dropdown-item>
                  </b-dropdown>
                </template>
            </b-table>
            <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>
          </b-col>
        </b-row>
      </b-card>

      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>
            <b-button type="button" variant="outline-dark" size="sm" @click="$router.go(-1)">
              <i class="fa fa-angle-double-left"></i>
              Volver
            </b-button>                        
          </b-col>

          <b-col>
            <nav>
              <b-pagination class="pull-right mb-0"
                            size="sm"
                            pills=""
                            :total-rows="getRowCount(table.items)"
                            :per-page="table.perPage"
                            v-model="table.currentPage" />
            </nav>
          </b-col>          
        </b-row>
      </b-card>

      <!-- ########################### -->
      <!-- #####     MODALES     ##### -->
      <!-- ########################### -->

      <!-- CRUD MAIN -->
      <b-modal v-model="modal.form.active"
              header-bg-variant="dark"
              header-text-variant="white">
        <div slot="modal-header">
          {{this.modal.form.title}}
        </div>

        <b-row>   
          <b-col md="12">
            <b-row>       
              <b-col md="5">
                <b-form-group label="Imagen">
                  <Imagen :images="crud.form.image" 
                          @load-image="crud.form.image = $event" 
                          typeImage="Imagen"
                          :compressImage="true"/>
                </b-form-group>  
              </b-col>              
              <b-col md="7">
                <b-row>   
                  <b-col md="12">
                    <b-form-group label="Nombre">
                      <b-form-input type="text"
                                    size="sm"
                                    v-model="crud.form.name"                                    
                                    placeholder="Ingresar un nombre">
                      </b-form-input>
                    </b-form-group>
                  </b-col>                  
                  <b-col md="12">
                    <b-form-group label="Orden">
                      <b-form-input type="number"
                                    size="sm"
                                    v-model="crud.form.order"                                  
                                    placeholder="Ingresar el orden">
                      </b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col sm="12">
                    <b-form-group label="Activar">                
                      <b-form-checkbox v-model="crud.form.status" size="sm" switch></b-form-checkbox>                 
                    </b-form-group>
                  </b-col>                  
                </b-row>
              </b-col>
            </b-row>
          </b-col>                
          <b-col md="6">
            <b-row>
              <b-col md="12">
                <b-form-group label="Texto Botón">
                  <b-form-input type="text"
                                size="sm"
                                v-model="crud.form.text_button"                                
                                placeholder="Texto del botón">
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group label="Link">
                  <b-form-input type="text"
                                size="sm"
                                v-model="crud.form.link"                                  
                                placeholder="Link de redirección del botón">
                  </b-form-input>
                </b-form-group>
              </b-col>
            </b-row>                  
          </b-col>
          <b-col md="6">
            <b-row>
              <b-col md="12">
                <b-form-group label="Título">
                  <b-form-input type="text"
                                size="sm"
                                v-model="crud.form.title"
                                placeholder="Ingresar un título">
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group label="Subtítulo">
                  <b-form-input type="text"
                                size="sm"
                                v-model="crud.form.subtitle"
                                placeholder="Ingresar un subtítulo">
                  </b-form-input>
                </b-form-group>
              </b-col>
            </b-row>      
          </b-col>          
        </b-row>

        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="modal.form.active=false">Cancelar</b-button>
          <b-button variant="dark" @click="save()">Guardar</b-button>          
        </div>
      </b-modal>
    </b-col>
  </b-row>
</template>
<script>
  import serviceAPI from './../services'
  import Error from '@/handler/error'
  import Session from '@/handler/session'  
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper' 
  import Imagen from '@/components/inc/image/image'

  export default {
    components: {
      Imagen,
    },        
    data: () => {
      return {      
        access: {
          module_id: Modules.CONTENIDOS,
          profile_id: Profiles.PERSONAL,
          view_reference: 'crudSliders',
          elements: {}
        }, 
        table : {
          items: [],
          fields: [
            {key: 'id', label: 'ID', sortable: true, class:"align-middle"},
            {key: 'image', label: 'Imagen', class:"align-middle"},
            {key: 'name', label: 'Nombre', class:"align-middle"},            
            {key: 'titulo', label: 'Título', class:"align-middle"},
            {key: 'boton', label: 'Botón', class:"align-middle"},    
            {key: 'status', label: 'Estado', class:"align-middle"},
            {key: 'order', label: 'Orden', class:"align-middle"}, 
            {key: 'f_action', label:'', class:"align-middle"},
          ],
          filter: null,
          mostrarFiltros: false,
          currentPage: 1,
          perPage: 50,
        },
        crud: {
          form: {
            id: 0,
            name: '',
            order: 0,
            link: '',
            text_button: '',
            image: [],
            title: '',
            subtitle: '',
            status: false,
          },          
        },
        modal: {
          form: {
            active: false,
            title: ''
          },
        },
        imageProps: {                             
          height: 50,          
        }                   
      }
    },
    created () {     
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)
      /* Fin configuracion */
    },  
    mounted() {
      this.load()
    },
    methods: {
      getRowCount (items) {
        return items.length
      },
      load() {
        var result = serviceAPI.obtenerSliders()

        result.then((response) => {
          var data = response.data
          this.table.items = data
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error))
        });        
      },
      add() {
        this.crud.form.id = 0
        this.crud.form.name = ''        
        this.crud.form.order = 0
        this.crud.form.link = ''
        this.crud.form.text_button = ''
        this.crud.form.image = []
        this.crud.form.title = ''
        this.crud.form.subtitle = ''
        this.crud.form.status= false

        this.modal.form.title = "Nuevo Sliders"
        this.modal.form.active = true
      },
      edit(item) {
        this.crud.form.id = item.id
        this.crud.form.name = item.name
        this.crud.form.order = item.order
        this.crud.form.link = item.link
        this.crud.form.text_button = item.text_button
        this.crud.form.image = item.image
        this.crud.form.title = item.title
        this.crud.form.subtitle = item.subtitle
        this.crud.form.status= item.status

        if(item.image.length>0) {
          this.crud.form.image = [{ path: item.image }]
        } else {
          this.crud.form.image = []
        }

        this.modal.form.title = "Editar Sliders"
        this.modal.form.active = true
      },
      remove(item) {
        this.crud.form.id = item.id
        this.crud.form.name = item.name        

        this.$bvModal.msgBoxConfirm('¿Desea borrar el item (' + this.crud.form.id + ') - '+ this.crud.form.name + '?', {
          title: 'Borrar Sliders',
          size: 'lg',
          buttonSize: 'lg',
          okVariant: 'danger',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'danger',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {
            let loader = this.$loading.show();
            var result = serviceAPI.eliminarSliders(this.crud.form.id);

            result.then((response) => {      
              this.modal.form.active = false        
              loader.hide()
              this.load()
              this.$awn.success("Registro eliminado");
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })
      },
      save() {        
        let loader = this.$loading.show();
        
        var image = new FormData();
        this.crud.form.image.forEach((value, key) => {
          image.append(key, value.path);
        })

        if (this.crud.form.id) {
          var result = serviceAPI.editarSliders(JSON.stringify(this.crud.form), image);
        } else {
          var result = serviceAPI.agregarSliders(JSON.stringify(this.crud.form), image);
        }

        result.then((response) => {
          this.modal.form.active = false
          loader.hide()
          this.load()
          this.$awn.success("Datos guardados con éxito");
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error));
        })
      },
    }    
  }
</script>